import { UserRoles } from 'entities/User/types';

export const Prefixes = {
	// root prefix
	root: '/',

	// grouping prefixes
	startRecognition: 'start-recognition/',
	customModel: 'model/',
	files: 'files',
	importModel: 'import-model/',
	importCustomFilesDirect: 'import-direct-model/',
	importCustomFilesFromFtp: 'import-ftp-model/',
	editFile: 'edit-file/',
	editModelFile: 'edit-model-file/',
	coUsers: 'co-users/',
	integrations: 'integrations',
	signUp: 'sign-up/',
	signIn: 'sign-in/',
	forgotPassword: 'forgot-password/',
	createPassword: 'create-password/',
	changeEmail: 'change-email/',
	users: 'users/',
	resources: 'resources/',
	extensions: 'extensions',
	partners: 'partners',
	syncSettings: 'sync-settings',
	signOut: '/sign-out',
};

export const RoutesMap = {
	startRecognition: {
		root: '',
		importFilesDirect: 'import-direct',
		importFilesFromFtp: 'import-ftp',
	},
	customModel: {
		root: 'custom-model',
		importModel: 'import-model',
	},
	importModel: {
		root: ':modelId',
	},
	files: {
		category: '/:category',
	},
	editFile: {
		root: ':fileId',
	},
	subscriptions: 'subscriptions',
	extensions: {
		root: '',
		exList: '/list',
		exDetails: ':exName',
	},
	coUsers: {
		root: '',
		details: 'details',
	},
	personalAccount: 'personal-account',
	integrations: {
		// apiSettings: 'api-settings',
		ftpSettings: '/ftp-settings',
	},
	support: 'support',

	signUp: {
		root: '',
		emailSent: 'email-sent',
		emailReceived: 'email-received/:token',
		byInvitation: 'by-invitation/:token',
	},
	signIn: {
		root: '',
		removedAccount: 'account-removed',
	},
	forgotPassword: {
		root: '',
		emailSent: 'email-sent',
	},
	createPassword: {
		root: ':token',
		linkExpired: 'link-expired',
		success: 'success',
	},
	changeEmail: {
		root: '',
		emailSent: 'email-sent',
	},
	privacyPolicy: 'privacy-policy',
	playground: 'playground',
	users: {
		root: '',
		details: ':userId',
	},
	resources: 'resources',

	partners: {
		root: '',
		settings: '/settings',
		settingsWithId: '/:partnerId/settings',
		documents: '/documents',
		documentsWithId: '/:partnerId/documents',
	},
};

export const Routes = {
	startRecognition: `${Prefixes.root}${RoutesMap.startRecognition.root}`,
	importFilesDirect: `${Prefixes.root}${Prefixes.startRecognition}${RoutesMap.startRecognition.importFilesDirect}`,
	importFilesFromFtp: `${Prefixes.root}${Prefixes.startRecognition}${RoutesMap.startRecognition.importFilesFromFtp}`,

	customModel: `${Prefixes.root}${RoutesMap.customModel.root}`,
	importCustomFilesRoot: `${Prefixes.root}${Prefixes.importModel}${RoutesMap.importModel.root}`,
	importFilesCustomModel: `${Prefixes.root}${Prefixes.importCustomFilesDirect}${RoutesMap.importModel.root}`,
	importFilesFromFtpCustomModel: `${Prefixes.root}${Prefixes.importCustomFilesFromFtp}${RoutesMap.importModel.root}`,
	editModelFileRoot: `${Prefixes.root}${Prefixes.editModelFile}${RoutesMap.importModel.root}`,

	filesRoot: `${Prefixes.root}${Prefixes.files}`,
	filesWithCategory: `${Prefixes.root}${Prefixes.files}${RoutesMap.files.category}`,

	editFileRoot: `${Prefixes.root}${Prefixes.editFile}${RoutesMap.editFile.root}`,

	subscriptions: `${Prefixes.root}${RoutesMap.subscriptions}`,

	extensions: `${Prefixes.root}${Prefixes.extensions}`,
	extensionsList: `${Prefixes.root}${Prefixes.extensions}${RoutesMap.extensions.exList}`,
	extensionDetails: `${Prefixes.root}${Prefixes.extensions}/${RoutesMap.extensions.exDetails}`,

	coUsers: `${Prefixes.root}${Prefixes.coUsers}${RoutesMap.coUsers.root}`.slice(0, -1),
	coUsersDetails: `${Prefixes.root}${Prefixes.coUsers}${RoutesMap.coUsers.details}`,

	partners: `${Prefixes.root}${Prefixes.partners}`,
	partnersSettings: `${Prefixes.root}${Prefixes.partners}${RoutesMap.partners.settings}`,
	partnersSettingsWithId: `${Prefixes.root}${Prefixes.partners}${RoutesMap.partners.settingsWithId}`,
	partnersDocuments: `${Prefixes.root}${Prefixes.partners}${RoutesMap.partners.documents}`,
	partnersDocumentsWithId: `${Prefixes.root}${Prefixes.partners}${RoutesMap.partners.documentsWithId}`,

	syncSettings: `${Prefixes.root}${Prefixes.syncSettings}`,

	personalAccount: `${Prefixes.root}${RoutesMap.personalAccount}`,

	integrationsRoot: `${Prefixes.root}${Prefixes.integrations}`,
	// integrationsApiSettings: `${Prefixes.root}${Prefixes.integrations}${RoutesMap.integrations.apiSettings}`,
	integrationsFtpSettings: `${Prefixes.root}${Prefixes.integrations}${RoutesMap.integrations.ftpSettings}`,

	support: `${Prefixes.root}${RoutesMap.support}`,

	signUp: `${Prefixes.root}${Prefixes.signUp}${RoutesMap.signUp.root}`,
	signUpEmailSend: `${Prefixes.root}${Prefixes.signUp}${RoutesMap.signUp.emailSent}`,
	signUpEmailReceived: `${Prefixes.root}${Prefixes.signUp}${RoutesMap.signUp.emailReceived}`,
	signUpByInvitation: `${Prefixes.root}${Prefixes.signUp}${RoutesMap.signUp.byInvitation}`,
	signIn: `${Prefixes.root}${Prefixes.signIn}${RoutesMap.signIn}`,

	forgotPassword: `${Prefixes.root}${Prefixes.forgotPassword}${RoutesMap.forgotPassword.root}`,
	forgotPasswordSent: `${Prefixes.root}${Prefixes.forgotPassword}${RoutesMap.forgotPassword.emailSent}`,

	createPassword: `${Prefixes.root}${Prefixes.createPassword}${RoutesMap.createPassword.root}`,
	createPasswordLinkExpired: `${Prefixes.root}${Prefixes.createPassword}${RoutesMap.createPassword.linkExpired}`,
	createPasswordSuccess: `${Prefixes.root}${Prefixes.createPassword}${RoutesMap.createPassword.success}`,

	changeEmail: `${Prefixes.root}${Prefixes.changeEmail}${RoutesMap.changeEmail.root}`,
	changeEmailSent: `${Prefixes.root}${Prefixes.changeEmail}${RoutesMap.changeEmail.emailSent}`,

	privacyPolicy: `${Prefixes.root}${RoutesMap.privacyPolicy}`,

	playground: `${Prefixes.root}${RoutesMap.playground}`,

	users: `${Prefixes.root}${Prefixes.users}`.slice(0, -1),
	userDetails: `${Prefixes.root}${Prefixes.users}${RoutesMap.users.details}`,
	resources: `${Prefixes.root}${RoutesMap.resources}`,
};

export const RoleBasedRoutes: Record<
	string,
	{
		path: string;
		link?: string;
		access: Array<(typeof UserRoles)[keyof typeof UserRoles]>;
	}
> = {
	startRecognition: {
		path: Routes.startRecognition,
		access: [UserRoles.user, UserRoles.coUser],
	},
	customModel: {
		path: Routes.customModel,
		access: [UserRoles.user, UserRoles.coUser],
	},
	importCustomFilesRoot: {
		path: '/import-model',
		access: [UserRoles.user, UserRoles.coUser],
	},
	importCustomFilesDirect: {
		path: '/import-direct-model',
		access: [UserRoles.user, UserRoles.coUser],
	},
	importCustomFilesFromFtp: {
		path: '/import-ftp-model',
		access: [UserRoles.user, UserRoles.coUser],
	},
	importFilesDirect: {
		path: Routes.importFilesDirect,
		access: [UserRoles.user, UserRoles.coUser],
	},
	importFilesFromFtp: {
		path: Routes.importFilesFromFtp,
		access: [UserRoles.user, UserRoles.coUser],
	},
	files: {
		path: Routes.filesRoot,
		access: [UserRoles.user, UserRoles.coUser],
	},
	editModelFile: {
		path: `/edit-model-file`,
		access: [UserRoles.user, UserRoles.coUser],
	},
	editFile: {
		path: `/edit-file`,
		access: [UserRoles.user, UserRoles.coUser],
	},
	subscriptions: {
		path: Routes.subscriptions,
		access: [UserRoles.user],
	},
	partners: {
		path: Routes.partners,
		access: [UserRoles.user],
	},
	partnersDetails: {
		path: Routes.partnersSettings,
		access: [UserRoles.user],
	},
	partnersDocuments: {
		path: Routes.partnersDocuments,
		access: [UserRoles.user],
	},
	syncSettings: {
		path: Routes.syncSettings,
		access: [UserRoles.user],
	},
	extensions: {
		path: Routes.extensions,
		access: [UserRoles.user, UserRoles.coUser],
	},
	extensionsList: {
		path: Routes.extensionsList,
		access: [UserRoles.user],
	},
	extensionDetails: {
		path: Routes.extensionDetails,
		access: [UserRoles.user, UserRoles.coUser],
	},
	coUsers: {
		path: Routes.coUsers,
		access: [UserRoles.user],
	},
	coUsersDetails: {
		path: Routes.coUsersDetails,
		access: [UserRoles.user],
	},
	personalAccount: {
		path: Routes.personalAccount,
		access: [UserRoles.user, UserRoles.coUser, UserRoles.admin],
	},
	// integrationsApiSettings: {
	// 	path: Routes.integrationsApiSettings,
	// 	access: [UserRoles.user, UserRoles.coUser],
	// },
	integrations: {
		path: Routes.integrationsRoot,
		access: [UserRoles.user, UserRoles.coUser],
	},
	integrationsFtpSettings: {
		path: Routes.integrationsFtpSettings,
		access: [UserRoles.user, UserRoles.coUser],
	},
	support: {
		path: Routes.support,
		access: [UserRoles.user, UserRoles.coUser],
	},
	playground: {
		path: Routes.playground,
		access: [UserRoles.user, UserRoles.coUser],
	},
	users: {
		path: Routes.users,
		access: [UserRoles.admin],
	},
	usersDetails: {
		path: Routes.userDetails,
		access: [UserRoles.admin],
	},
	resources: {
		path: Routes.resources,
		access: [UserRoles.admin],
	},
	signOut: {
		path: Prefixes.signOut,
		access: [UserRoles.admin, UserRoles.user, UserRoles.coUser],
	},
};
