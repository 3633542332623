import { useEffect } from 'react';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { getFileExport } from 'entities/Files/api';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export enum FileTypes {
	XLSX = 'XLSX',
	JSON = 'JSON',
	CSV = 'CSV',
	XML = 'XML',
}

export const DownloadModal = ({
	open,
	handleClose,
	fileId,
	handleFtpOpen,
	fileType,
	setFileType,
}: {
	open: boolean;
	handleClose: () => void;
	fileId: string[];
	handleFtpOpen: () => void;
	fileType?: FileTypes;
	setFileType: (type: FileTypes | undefined) => void;
}) => {
	const { t } = useTranslation();
	const { currentSubscription } = useSubscriptionStoreStore();

	useEffect(() => {
		if (open) {
			setFileType(undefined);
		}
	}, [open]);

	const downloadFile = async ({ fileId, type }: { fileId: string[]; type: string }) => {
		for (let i = 0; i < fileId.length; i++) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			await getFileExport({ fileId: fileId[i], type })
				.then((response: any) => {
					const disposition = response.headers.get('content-disposition');
					let filename = 'filename.json';
					if (disposition && disposition.indexOf('attachment') !== -1) {
						const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						const matches = filenameRegex.exec(disposition);
						if (matches != null && matches[1]) {
							filename = matches[1].replace(/['"]/g, '');
						}
					}
					const url = window.URL.createObjectURL(response.data);
					const a = document.createElement('a');
					a.href = url;
					a.download = filename;
					document.body.appendChild(a);
					a.click();
					a.remove();
				})
				.catch((error) => {
					const errorMessage = getErrorMessage(error);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				});
		}
	};

	const handleDownload = (type: string) => {
		//type must be one of the following values: XML, CSV, JSON,  XLSX
		downloadFile({ fileId, type: type });
	};

	const onOpenFtpImportFileModalHandler = () => {
		handleFtpOpen();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.download_modal.title') as string }}
			sx={{
				'& .MuiPaper-root': {
					width: '536px',
					maxWidth: '536px',
					overflow: 'inherit',
				},
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('shared_texts.download_modal.description')}
			</Typography>
			<CloseModalButton action={handleClose} />

			{!fileType && (
				<>
					<Button
						onClick={() => {
							setFileType(FileTypes.XLSX);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_xls')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							setFileType(FileTypes.JSON);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_json')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							setFileType(FileTypes.CSV);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_csv')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							setFileType(FileTypes.XML);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_xml')}
					</Button>
				</>
			)}
			{fileType && (
				<>
					<Button
						onClick={() => {
							handleDownload(fileType);
							handleClose();
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_on_computer')}
					</Button>

					{(currentSubscription?.subscriptionType === STypesEnum.PRO ||
						currentSubscription?.subscriptionType === STypesEnum.PAY_AS_YOU_GO ||
						currentSubscription?.subscriptionType === STypesEnum.UNLIMITED) && (
						<Button
							sx={{ marginTop: '24px' }}
							onClick={onOpenFtpImportFileModalHandler}
							variant={'contained'}
							fullWidth
						>
							{t('shared_texts.download_modal.download_on_ftp')}
						</Button>
					)}
				</>
			)}
		</Dialog>
	);
};
