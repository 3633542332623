import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FileType } from 'entities/Files/types';
import { useAuthStore } from 'processes/auth/model/state';
import filePreview from 'shared/assets/file_preview.png';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Checkbox } from 'shared/ui/Checkbox';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Icon } from 'shared/ui/Icon';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { Option } from 'shared/ui/OptionsDropdown/types';

export type FileForDashboard = FileType & {
	id: string;
	filesCount: number;
	selectedFiles: Array<string>;
	assertionsIsEnabled: boolean;
	toggleFile: (fileId: string) => void;
	toggleAllFiles: (refresh?: boolean) => void;
	edit: (fileId: string) => void;
	export: (fileId?: string) => void;
	deleteFile: (fileId?: string) => void;
	assertionsHandler: (fileId?: string) => void;
	isPartnerSection: boolean;
};

export const FilesDashboardColumns: Array<Column<FileForDashboard>> = [
	{
		field: 'select',
		commonCellSettings: {
			style: {
				width: '65px',
			},
		},
		headerCellSettings: {
			renderCell: (file) => {
				return file?.status !== 'IN_PROCESS' ? (
					<Checkbox
						checked={file?.filesCount === file?.selectedFiles.length}
						onChange={() => file?.toggleAllFiles()}
					/>
				) : null;
			},
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.status !== 'IN_PROCESS' ? (
					<Checkbox
						checked={file?.selectedFiles.includes(file?._id)}
						onChange={() => file?._id && file.toggleFile(file?._id)}
					/>
				) : null;
			},
		},
	},
	{
		field: 'icon',
		commonCellSettings: {
			style: {
				width: '65px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: (file) => {
				const OptionList: Option[] = [];
				if (file?.status === 'RECOGNIZED') {
					const assertionAction = {
						label: 'assertions.as_title',
						value: 'Assertions',
						action: () => {
							file && file.assertionsHandler();
						},
					};
					OptionList.push({
						label: 'shared_texts.table_dropdown.export',
						value: 'Export',
						action: () => {
							if (file?.selectedFiles.length) {
								file?._id && file.export();
							}
						},
					});
					if (file?.assertionsIsEnabled) {
						OptionList.push(assertionAction);
					}
				}
				if (file?.status !== 'IN_PROCESS') {
					OptionList.push({
						label: 'shared_texts.table_dropdown.remove',
						value: 'Remove',
						action: () => {
							file?._id && file.deleteFile();
						},
					});
				}
				return file?.status !== 'IN_PROCESS' ? (
					<OptionsDropdown
						iconId={'more_horizontal_small'}
						optionList={OptionList}
						forTableHeader={true}
					/>
				) : (
					<></>
				);
			},
		},
		bodyCellSettings: {
			renderCell: (file) => {
				if (file?.status === 'IN_PROCESS') {
					return <CircularProgress size={'14px'} sx={{ color: ColorPalette.royalBlue }} />;
				}
				if (file?.status === 'RECOGNIZED') {
					return (
						<Icon
							id={'done_ring_round'}
							color={ColorPalette.jungleGreen}
							style={{ width: '24px', height: '24px' }}
						/>
					);
				}
				return (
					<Icon
						id={'dell_fill'}
						color={ColorPalette.violetRed}
						style={{ width: '24px', height: '24px' }}
					/>
				);
			},
		},
	},
	{
		field: 'fileName',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_name" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={filePreview}
							alt={'file-preview'}
							style={{ width: '62px', height: '62px', marginRight: '12px' }}
						/>
						<Tooltip title={file?.fileName || '-'} placement={'top'}>
							<Typography
								variant={'body2'}
								color={ColorPalette.astronaut}
								sx={{
									width: '250px',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
								}}
							>
								{file?.fileName || '-'}
							</Typography>
						</Tooltip>
					</Box>
				);
			},
		},
	},
	{
		field: 'category',
		commonCellSettings: {
			style: {
				minWidth: '210px',
				maxWidth: '210px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="category" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.category ? (
					<TableTranslationLabel path={`sidebar.menu./files/${file?.category}`} />
				) : (
					'-'
				);
			},
		},
	},
	{
		field: 'importedAt',
		commonCellSettings: {
			style: {
				minWidth: '160px',
				maxWidth: '160px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_at" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.createdAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(file.createdAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'importedBy',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_by" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				const { user } = useAuthStore();
				let invitedByFullName = '-';
				if (file?.uploaderId.firstName && file.uploaderId.lastName) {
					invitedByFullName = `${file.uploaderId.firstName} ${file.uploaderId.lastName}`;
				}
				return (
					<>
						<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
							{invitedByFullName}
						</Typography>
						{file?.uploaderId._id === user?._id && (
							<Typography
								variant={'body2'}
								component={'span'}
								color={ColorPalette.astronaut}
								sx={{ fontWeight: 300 }}
							>
								{' (me)'}
							</Typography>
						)}
					</>
				);
			},
		},
	},
	{
		field: 'automaticRemovalAt',
		commonCellSettings: {
			style: {
				minWidth: '160px',
				maxWidth: '160px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="automatic_removal_at" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.autoRemoveAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(file.autoRemoveAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'importingOption',
		commonCellSettings: {
			style: {
				minWidth: '110px',
				maxWidth: '110px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="importing_option" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<Typography variant={'body2'} color={ColorPalette.astronaut}>
						{file?.uploadSource ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'Assertions',
		commonCellSettings: {
			style: {
				minWidth: '210px',
				maxWidth: '210px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: (file) =>
				file?.assertionsIsEnabled ? <TableTranslationLabel path="assertions.as_title_list" /> : null,
		},
		bodyCellSettings: {
			renderCell: (file) => (file?.assertionsIsEnabled ? file?.assertionSetsId || '-' : null),
		},
	},
	{
		field: 'autocomplete_icon',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: (file) =>
				file?.isPartnerSection ? <TableTranslationLabel label="doc_autocomplete" /> : null,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				if (file?.isPartnerSection) {
					return (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							{file.autocomplete ? (
								<Icon
									id={'done_ring_round'}
									color={ColorPalette.jungleGreen}
									style={{ width: '24px', height: '24px' }}
								/>
							) : (
								<Icon
									id={'dell_fill'}
									color={ColorPalette.violetRed}
									style={{ width: '24px', height: '24px' }}
								/>
							)}
						</Box>
					);
				}
				return null;
			},
		},
	},

	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (file) => {
				let OptionList: Option[] = [];
				if (file?.status === 'RECOGNIZED') {
					const assertionAction = {
						label: 'assertions.as_title',
						value: 'Assertions',
						action: () => {
							file && file.assertionsHandler(file?._id);
						},
					};
					const newOptionsArr = [
						{
							label: 'shared_texts.table_dropdown.edit',
							value: 'Edit',
							action: () => {
								file?._id && file.edit(file?._id);
							},
						},
						{
							label: 'shared_texts.table_dropdown.export',
							value: 'Export',
							action: () => {
								file?._id && file.export(file?._id);
							},
						},
					];
					OptionList = [...newOptionsArr];
					if (file?.assertionsIsEnabled) {
						OptionList.push(assertionAction);
					}
				}

				OptionList.push({
					label: 'shared_texts.table_dropdown.remove',
					value: 'Remove',
					action: () => {
						file?._id && file.deleteFile(file?._id);
					},
				});

				return file?.status !== 'IN_PROCESS' ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<OptionsDropdown iconId={'more_horizontal'} optionList={OptionList} />
					</Box>
				) : (
					<></>
				);
			},
		},
	},
];
