import { createTheme } from '@mui/material';

export const theme = createTheme({
	typography: {
		fontFamily: 'Comfortaa',
		h2: {
			fontSize: '60px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h3: {
			fontSize: '48px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h4: {
			fontSize: '34px',
			lineHeight: '38px',
			fontWeight: 700,
			letterSpacing: '0.25px',
		},
		h5: {
			fontSize: '24px',
			lineHeight: '27px',
			fontWeight: 700,
		},
		h6: {
			fontSize: '20px',
			lineHeight: '22px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		subtitle1: {
			fontSize: '18px',
			lineHeight: '20px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '19px',
			fontWeight: 600,
			letterSpacing: '0.5px',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 600,
			letterSpacing: '0.25px',
		},
		button: {
			fontSize: '18px',
			lineHeight: '16px',
			fontWeight: 700,
			letterSpacing: '0.75px',
			textTransform: 'capitalize',
		},
		caption: {
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 700,
			letterSpacing: '0.4px',
		},
		overline1: {
			fontSize: '10px',
			lineHeight: '16px',
			fontWeight: 600,
			letterSpacing: '0.4px',
			fontFamily: 'Comfortaa',
		},
		overline2: {
			fontSize: '8px',
			lineHeight: '12px',
			fontWeight: 600,
			letterSpacing: '1.5px',
			fontFamily: 'Comfortaa',
		},
	},
});
