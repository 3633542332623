import { Box, Dialog, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFtpClient } from 'entities/ImportSessions/api';
import { getFtpSettings } from 'entities/Integrations/api';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Icon } from 'shared/ui/Icon';
import { Loader } from 'shared/ui/Loader';
import { Paper } from 'shared/ui/Paper';
import { ItemDirectoryCard } from 'widgets/importFiles/importFilesFromFtp/ui/ItemDirectoryCard';
import { ItemFileCard } from 'widgets/importFiles/importFilesFromFtp/ui/ItemFileCard';
import { WarningModal } from 'widgets/importFiles/importFilesFromFtp/ui/WarningModal';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleImport: (localPath: string, selectedFiles: string[], isCompany: boolean) => void;
	exportFtp?: boolean;
	isPending?: boolean;
}

export const FtpImportFilesModal: FC<Props> = ({
	open,
	handleImport,
	handleClose,
	exportFtp = false,
	isPending = true,
}) => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { data: ftpSettingsData } = useQuery({
		queryKey: [exportFtp ? 'export-ftp-settings' : 'import-ftp-settings'],
		queryFn: () =>
			getFtpSettings({
				ftpSettingsType: exportFtp
					? FtpSettingsTypeEnum.generalExport
					: FtpSettingsTypeEnum.generalImport,
			}),
	});
	const [localPath, setLocalPath] = useState<string>('');
	const [selectedFiles, setSelectedFiles] = useState<Array<string>>([]);

	const isCompany = searchParams.get('iscompany') === 'true';

	const {
		data: ftpClient,
		isLoading: isFtpClientLoading,
		error: ftpClientError,
	} = useQuery({
		queryKey: ['ftp-client', localPath],
		queryFn: () =>
			getFtpClient({
				isCompanyFtp: isCompany,
				path: localPath.length ? localPath : '/',
				ftpSettingsType: exportFtp
					? FtpSettingsTypeEnum.generalExport
					: FtpSettingsTypeEnum.generalImport,
			}),
		retry: false,
		enabled: open,
		onError: (err) => {
			const defaultErrorMessage = t('files.snackbar.error_receiving_ftp');
			const errorMessage = getErrorMessage(err, defaultErrorMessage);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const handleSubmitImport = () => {
		handleImport(localPath, [...selectedFiles], isCompany);
	};

	const handleToggleFile = (fileName: string) => {
		setSelectedFiles((prevState) => {
			return prevState.includes(fileName)
				? prevState.filter((i) => i !== fileName)
				: [...prevState, fileName];
		});
	};
	const handleClearSelectedFiles = () => {
		setSelectedFiles([]);
	};

	const [tempLocalPath, setTempLocalPath] = useState<string | undefined>(undefined);
	const handleClearTempLocalPath = () => {
		setTempLocalPath(undefined);
	};
	const handleChangeLocalPath = useCallback(
		(directoryName: string | undefined, fromWarningModal?: boolean) => {
			if (!fromWarningModal && selectedFiles.length) {
				setTempLocalPath(directoryName);
				return handleOpenWarningModal();
			}
			setLocalPath((prevState) => {
				if (directoryName) {
					return prevState + '/' + directoryName;
				} else {
					return prevState.slice(0, prevState.lastIndexOf('/'));
				}
			});
		},
		[selectedFiles],
	);

	const isGoBackBtnDisabled = useMemo(() => {
		return isFtpClientLoading || localPath.length === 0;
	}, [isFtpClientLoading, localPath]);

	const ftpFullPath = useMemo(() => {
		if (!ftpSettingsData?.data) {
			return '-';
		}
		return `ftp://${ftpSettingsData?.data?.host}:${ftpSettingsData?.data?.port}${
			ftpSettingsData?.data?.path
		}${localPath.substring(1)}`;
	}, [ftpSettingsData?.data, localPath]);

	const protocol = ftpSettingsData?.data?.protocol?.toUpperCase();
	const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
	const handleOpenWarningModal = () => setOpenWarningModal(true);
	const handleCloseWarningModal = () => setOpenWarningModal(false);

	return (
		<>
			{openWarningModal && (
				<WarningModal
					newLocalPath={tempLocalPath}
					handleClose={handleCloseWarningModal}
					handleChangeLocalPath={handleChangeLocalPath}
					handleClearSelectedFiles={handleClearSelectedFiles}
					handleClearTempLocalPath={handleClearTempLocalPath}
				/>
			)}
			<Dialog
				open={open}
				onClose={handleClose}
				PaperComponent={Paper}
				PaperProps={{
					title: t('files.modal.import_ftp_title', { value: protocol ?? 'FTP' }) as string,
				}}
				sx={{
					'& .MuiPaper-root': {
						width: '984px',
						maxWidth: '984px',
						overflow: 'inherit',
					},
				}}
			>
				<CloseModalButton action={handleClose} />
				{isPending ? (
					<Loader />
				) : (
					<>
						<Typography variant={'body1'} color={ColorPalette.baliHai}>
							{exportFtp
								? t('files.modal.export_ftp_description')
								: t('files.modal.import_ftp_description')}
						</Typography>
						<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0 24px 0' }} />
						<Box
							sx={{
								display: 'flex',
								flexDirectio: 'rov',
								alignItems: 'center',
								marginBottom: '24px',
							}}
						>
							<Box
								sx={{
									opacity: isGoBackBtnDisabled ? '.5' : '1',
									cursor: isGoBackBtnDisabled ? 'default' : 'pointer',
								}}
								onClick={() => {
									!isGoBackBtnDisabled && handleChangeLocalPath(undefined);
								}}
							>
								<Icon id={'arrow_left'} color={ColorPalette.astronaut} />
							</Box>

							<Typography
								variant={'body2'}
								color={ColorPalette.astronaut}
								sx={{ marginLeft: '14px' }}
							>
								{ftpFullPath}
							</Typography>
						</Box>

						{isFtpClientLoading ? (
							<Loader />
						) : !isFtpClientLoading && ftpClientError ? (
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									minHeight: '350px',
								}}
							>
								<Typography variant={'body1'} color={ColorPalette.baliHai}>
									{t('files.modal.import_ftp_error')}
								</Typography>
							</Box>
						) : (
							<Box
								sx={{
									width: '100%',
									height: '350px',
									overflowY: 'auto',
									display: 'grid',
									gridTemplateColumns: 'repeat(3, 1fr)',
									gridGap: '20px',
								}}
							>
								{ftpClient?.data?.map((item, index) => {
									const itemId = `${index}-${item.type}-${item.name}`;

									switch (item.type) {
										case 'directory':
											return (
												<ItemDirectoryCard
													key={itemId}
													directoryName={item.name}
													onGoToDirectory={handleChangeLocalPath}
												/>
											);
										case 'file':
											return (
												<ItemFileCard
													key={itemId}
													fileName={item.name}
													fileSize={item.size}
													checked={selectedFiles.includes(item.name)}
													onToggle={handleToggleFile}
													exportFtp={exportFtp}
												/>
											);
									}
								})}
							</Box>
						)}
						<Box
							sx={{
								'paddingTop': '44px',
								'width': '100%',
								'display': 'flex',
								'flexDirection': 'row',
								'alignItems': 'center',
								'justifyContent': 'space-between',

								'& > .MuiBox-root': {
									width: '390px',
								},
							}}
						>
							{!exportFtp && (
								<Typography variant={'body2'} color={ColorPalette.astronaut}>
									{t('files.modal.import_ftp_selected', { value: selectedFiles.length })}
								</Typography>
							)}
							<ActionsForDialog
								sx={{ marginLeft: 'auto' }}
								cancelLabel={t('shared_texts.cancel')}
								cancelAction={handleClose}
								applyLabel={
									exportFtp
										? t('shared_texts.export')
										: (t('shared_texts.import') as string)
								}
								applyAction={handleSubmitImport}
							/>
						</Box>
					</>
				)}
			</Dialog>
		</>
	);
};
